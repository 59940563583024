<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >

    <b-modal
        id="modal-create"
        cancelTitle="Cancel"
        centered
        ok-title="Create"
        title="Create Platform"
        @ok.prevent="createPlatform"
    >
      <validation-observer ref="createPlatformForm" class="row">
        <validation-provider
            #default="{ errors }"
            class="col-12"
            name="platform-name"
            rules="required"
        >
          <b-form-group
              label="Name"
              label-for="platform-name"
          >
            <b-form-input
                id="platform-name"
                v-model="platform.name"
                :state="errors.length > 0 ? false:null"
                name="platform-name"
                placeholder="platform name..."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </validation-observer>
    </b-modal>
    <b-modal
        id="modal-update"
        cancelTitle="Cancel"
        centered
        ok-title="Update"
        title="Update Platform"
        @ok.prevent="updatePlatform"
    >
      <validation-observer v-if="selectedPlatform" ref="updatePlatformForm" class="row">
        <validation-provider
            #default="{ errors }"
            class="col-12"
            name="platform-name"
            rules="required"
        >
          <b-form-group
              label="Name"
              label-for="platform-name"
          >
            <b-form-input
                id="platform-name"
                v-model="selectedPlatform.name"
                :state="errors.length > 0 ? false:null"
                name="platform-name"
                placeholder="platform name..."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </validation-observer>
    </b-modal>
    <b-modal
        id="modal-delete"
        cancelTitle="Cancel"
        centered
        ok-title="Delete"
        title="Delete Platform"
        @ok.prevent="deletePlatform"
    >
      <div class="row">
        <div class="col-md-12">
          <span>Are you sure you want to delete this platform ?</span>
        </div>
      </div>
    </b-modal>

    <div v-if="platforms !== null">

      <!-- Table Container Card -->
      <b-card
          class="mb-0"
      >

        <h1>Platforms</h1>

        <div class="my-2">
          <!-- Table Top -->
          <b-row>
            <b-col class="mt-1 mb-lg-0 d-flex align-items-center  mb-1 mt-md-0" cols="12"
                   md="2"

            >
              <b-button
                  v-b-modal.modal-create
                  class="w-100 mt-1"
                  variant="success"
              >
                Create Platform
              </b-button>
            </b-col>
            <b-col class="mb-1 mb-md-0"
                   md="3"
            >
              <b-form-group label="Count">
                <v-select
                    v-model="perPage"
                    :clearable="false"
                    :options="perPageOptions"
                    class="per-page-selector  "
                />
              </b-form-group>
            </b-col>


          </b-row>
        </div>

        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="platforms"
            bordered
            class="position-relative"
            empty-text="Nothing found !"
            primary-key="id"
            responsive
            show-empty
            striped
        >


          <template #cell(operation)="data">
            <div class="d-flex align-items-center  w-100">
              <span v-b-modal.modal-update class="cursor-pointer mx-1" @click="setSelectedPlatform(data.item)">

              <feather-icon
                  class="text-primary"
                  icon="EditIcon"
                  size="20"
              />
              </span>
              <span v-b-modal.modal-delete class="cursor-pointer mx-1" @click="setSelectedPlatform(data.item)">
              <feather-icon
                  class="text-danger"
                  icon="TrashIcon"
                  size="20"
              />
              </span>
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
                class="d-flex align-items-center justify-content-center"
                cols="12"
                sm="12"
            >
              <!--            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
            </b-col>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
            >
              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>

import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import Helper from '@/libs/Helper'
import { CreatePlatform, DeletePlatform, GetAllPlatformsByPage, UpdatePlatform } from '@/libs/Api/platforms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'

export default {
  title: 'manage-platforms',
  name: 'manage-platforms',
  data() {
    return {
      required,
      platforms: null,
      totalCount: null,
      showOverlay: false,
      currentPage: 1,
      platform: {
        name: '',
      },
      baseURL: Helper.baseUrl + 'media/gallery/profile/',
      perPage: 10,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'id',
          label: '#'
        },
        {
          key: 'name',
          label: 'name'
        },
        {
          key: 'operation',
          label: 'operation'
        },
      ],
      pageNumber: 1,
      selectedPlatform: null,
    }
  },
  async created() {
    await Promise.all([
      this.getPlatforms()
    ])
  },
  watch: {
    currentPage: function () {
      this.getPlatforms()
    },

  },
  methods: {
    setSelectedPlatform(item) {
      this.selectedPlatform = JSON.parse(JSON.stringify(item))
    },
    async getPlatforms() {
      let _this = this
      _this.showOverlay = true
      let getAllPlatforms = new GetAllPlatformsByPage(_this)
      let data = {
        pageNumber: _this.currentPage,
        count: _this.perPage,
      }
      getAllPlatforms.setParams(data)
      await getAllPlatforms.fetch(function (content) {
        _this.platforms = content.data.data
        _this.totalCount = content.data.totalCount
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async createPlatform() {
      let _this = this
      Helper.validateForm(_this, 'createPlatformForm', async () => {
        _this.showOverlay = true
        let createPlatform = new CreatePlatform(_this)
        createPlatform.setParams(_this.platform)
        await createPlatform.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            props: {
              title: `success`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `platform successfully created`,
            },
          })
          _this.platform = {
            name: '',
            exchangeRate: null,
            code: ''
          }
          _this.getPlatforms()
          _this.showOverlay = false
          _this.$bvModal.hide('modal-create')

        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })
    },
    async updatePlatform() {
      let _this = this
      Helper.validateForm(_this, 'updatePlatformForm', async () => {
        _this.showOverlay = true
        let updatePlatform = new UpdatePlatform(_this)
        updatePlatform.setParamsQuery(_this.selectedPlatform.id)
        updatePlatform.setParams(_this.selectedPlatform)
        await updatePlatform.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            props: {
              title: `success`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `platform successfully updated`,
            },
          })
          _this.getPlatforms()
          _this.selectedPlatform = null
          _this.$bvModal.hide('modal-update')
          _this.showOverlay = false
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      })

    },
    async deletePlatform() {
      let _this = this
      _this.showOverlay = true
      let deletePlatform = new DeletePlatform(_this)
      deletePlatform.setParams({ id: _this.selectedPlatform.id })
      await deletePlatform.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `platform successfully deleted`,
          },
        })
        _this.getPlatforms()
        _this.$bvModal.hide('modal-delete')
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })

    },

  },

  components: {
    BCard,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BPagination,
    BOverlay,
    BModal,
    vSelect,
  },
}
</script>

<style scoped>

</style>
